import axios from 'axios';

let BASE_URL = process.env.REACT_APP_BASE_URL;

export const APIGENERIC = () => {
  const API_GENERIC = axios.create({
    baseURL: BASE_URL
  });
  const login = params => {
    const dataLogin = {
      email: params.email,
      password: params.password,
      notificationToken: params.notificationToken
    };
    return API_GENERIC.post('admin/v1/auth/login', dataLogin);
  };

  const requestForgotPassword = obj => {
    return API_GENERIC.post('admin/v1/auth/send-forgot-password-email', obj);
  };

  const verifyOtp = obj => {
    return API_GENERIC.post('admin/v1/auth/verify-otp', obj);
  };

  const resetPassword = obj => {
    return API_GENERIC.put('admin/v1/auth/reset-password', obj);
  };

  return {
    login,
    requestForgotPassword,
    verifyOtp,
    resetPassword
  };
};

export const API = () => {
  const API_URL = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'ngrok-skip-browser-warning': true
    }
  });

  const logout = () => {
    return API_URL.get('admin/v1/auth/logout');
  };

  const adminList = ({
    page = 1,
    limit = 10,
    keyword = '',
    isActive = '',
    role = ''
  }) => {
    let queryString = `?page=${page}&limit=${limit}`;
    let keywordQuery = '';
    let isActiveQuery = '';
    let roleQuery = '';

    if (keyword !== '') {
      keywordQuery = `&keyword=${keyword}`;
    }

    if (isActive !== '') {
      isActiveQuery = `&isActive=${isActive}`;
    }

    if (role !== '') {
      roleQuery = `&role=${role}`;
    }

    return API_URL.get(
      `admin/v1/admins${queryString}${keywordQuery}${isActiveQuery}${roleQuery}`
    );
  };

  const adminDetail = id => {
    return API_URL.get(`admin/v1/admins/${id}`);
  };

  const createAdmin = obj => {
    const formData = new FormData();
    formData.append('name', obj.name);
    formData.append('email', obj.email);
    formData.append('password', obj.password);
    formData.append('role', obj.role);
    formData.append('isActive', obj.isActive);

    if (typeof obj.imageUrl === 'object') {
      const datafile = obj.imageUrl;
      for (var i = 0; i < datafile.length; i++) {
        formData.append('image', datafile[i]);
      }
      formData.append('imageUrl', '');
    } else {
      formData.append('imageUrl', obj.imageUrl);
    }
    return API_URL.post('admin/v1/admins', formData);
  };

  const updateAdmin = (id, obj) => {
    const formData = new FormData();
    formData.append('name', obj.name);
    formData.append('email', obj.email);
    formData.append('role', obj.role);
    formData.append('isActive', obj.isActive);
    if (obj.imageUrl && typeof obj.imageUrl === 'object') {
      const datafile = obj.imageUrl;
      for (var i = 0; i < datafile.length; i++) {
        formData.append('image', datafile[i]);
      }
      formData.append('imageUrl', '');
    } else {
      formData.append('imageUrl', obj.imageUrl);
    }
    return API_URL.put(`admin/v1/admins/${id}`, formData);
  };

  const updatePassword = (id, obj) => {
    return API_URL.put(`admin/v1/admins/${id}/change-password`, obj);
  };

  const deleteAdmin = id => {
    return API_URL.delete(`admin/v1/admins/${id}`);
  };

  const partnerList = ({ page, limit, keyword, type }) => {
    let queryString = `?page=${page}&limit=${limit}`;
    let keywordQuery = '';
    let typeQuery = '';

    if (keyword !== '') {
      keywordQuery = `&keyword=${keyword}`;
    }

    if (type !== '') {
      typeQuery = `&type=${type}`;
    }

    return API_URL.get(
      `admin/v1/partners${queryString}${keywordQuery}${typeQuery}`
    );
  };

  const partnerDetail = id => {
    return API_URL.get(`admin/v1/partners/${id}`);
  };

  const createPartner = obj => {
    return API_URL.post('admin/v1/partners', obj);
  };

  const updatePartner = (id, obj) => {
    return API_URL.put(`admin/v1/partners/${id}`, obj);
  };

  const partnerMemberList = (id, page = 1, limit = 10) => {
    return API_URL.get(
      `admin/v1/partners/members/${id}?page=${page}&limit=${limit}`
    );
  };

  const sendEmailMember = (id, obj) => {
    return API_URL.post(`admin/v1/partners/members/${id}`, obj);
  };

  const resendEmailMember = (id, obj) => {
    return API_URL.post(`admin/v1/partners/members/${id}/resend`, obj);
  };

  const tagList = ({
    page = 1,
    limit = 10,
    search = '',
    isActive = '',
    orderBy = 'name',
    orderDirection = 'asc'
  }) => {
    let queryString = `?page=${page}&limit=${limit}`;
    let searchQuery = '';
    let isActiveQuery = '';
    let isOrderByQuery = '';
    let isOrderDirection = '';

    if (search !== '') {
      searchQuery = `&name=${search}`;
    }

    if (isActive !== '') {
      isActiveQuery = `&isActive=${isActive}`;
    }

    if (orderBy !== '') {
      isOrderByQuery = `&orderBy[]=${orderBy}`;
    }

    if (orderDirection !== '') {
      isOrderDirection = `&orderDirection[]=${orderDirection}`;
    }

    return API_URL.get(
      `admin/v1/tags${queryString}${searchQuery}${isActiveQuery}${isOrderByQuery}${isOrderDirection}`
    );
  };

  const tagDetail = id => {
    return API_URL.get(`admin/v1/tags/${id}`);
  };

  const createTag = obj => {
    return API_URL.post('admin/v1/tags', obj);
  };

  const updateTag = (id, obj) => {
    return API_URL.put(`admin/v1/tags/${id}`, obj);
  };

  const deleteTag = id => {
    return API_URL.delete(`admin/v1/tags/${id}`);
  };

  const activityList = ({ page, limit, search }) => {
    let queryString = `?page=${page}&limit=${limit}`;

    if (search !== '') {
      queryString = `?page=${page}&limit=${limit}&name=${search}`;
    }

    return API_URL.get(`admin/v1/activities${queryString}`);
  };

  const activityDetail = id => {
    return API_URL.get(`admin/v1/activities/${id}`);
  };

  const createActivity = obj => {
    return API_URL.post('admin/v1/activities', obj);
  };

  const updateActivity = (id, obj) => {
    return API_URL.put(`admin/v1/activities/${id}`, obj);
  };

  const deleteActivity = id => {
    return API_URL.delete(`admin/v1/activities/${id}`);
  };

  const categoryList = ({
    page = 1,
    limit = 10,
    type = 'article',
    isActive = '',
    orderBy = 'name',
    orderDirection = 'asc'
  }) => {
    let queryString = `?page=${page}&limit=${limit}`;
    let typeQuery = '';
    let isActiveQuery = '';
    let isOrderByQuery = '';
    let isOrderDirection = '';

    if (type !== '') {
      typeQuery = `&type=${type}`;
    }

    if (isActive !== '') {
      isActiveQuery = `&isActive=${isActive}`;
    }

    if (orderBy !== '') {
      isOrderByQuery = `&orderBy[]=${orderBy}`;
    }

    if (orderDirection !== '') {
      isOrderDirection = `&orderDirection[]=${orderDirection}`;
    }

    return API_URL.get(
      `admin/v1/categories${queryString}${typeQuery}${isActiveQuery}${isOrderByQuery}${isOrderDirection}`
    );
  };

  const categoryDetail = id => {
    return API_URL.get(`admin/v1/categories/${id}`);
  };

  const createCategory = obj => {
    const datafile = obj.imageUrl;
    const formData = new FormData();
    formData.append('name', obj.name);
    formData.append('isActive', obj.isActive);
    formData.append('type', obj.type);
    for (var i = 0; i < datafile.length; i++) {
      formData.append('image', datafile[i]);
    }
    return API_URL.post('admin/v1/categories', formData);
  };

  const updateCategory = (id, obj) => {
    const formData = new FormData();
    formData.append('name', obj.name);
    formData.append('isActive', obj.isActive);
    formData.append('type', obj.type);
    if (typeof obj.imageUrl === 'object') {
      const datafile = obj.imageUrl;
      for (var i = 0; i < datafile.length; i++) {
        formData.append('image', datafile[i]);
      }
      formData.append('imageUrl', '');
    } else {
      formData.append('imageUrl', obj.imageUrl);
    }

    return API_URL.put(`admin/v1/categories/${id}`, formData);
  };

  const deleteCategory = id => {
    return API_URL.delete(`admin/v1/categories/${id}`);
  };

  const configurationDetail = () => {
    return API_URL.get('admin/v1/configs');
  };

  const updateConfiguration = obj => {
    const updateConfigObj = {
      duration: obj?.duration,
      coachingPause: obj?.coachingPause,
      numberOfConsiltation: obj?.amountOfConsultation,
      numberOfConsiltationDay: obj?.amountOfConsultationPerDay
    };
    return API_URL.put('admin/v1/configs', updateConfigObj);
  };

  const articleList = ({
    page = 1,
    limit = 10,
    search = '',
    status = '',
    category = '',
    articleType = 'general'
  }) => {
    let queryString = `?page=${page}&limit=${limit}`;
    let searchQuery = '';
    let statusQuery = '';
    let categoryQuery = '';
    let articleTypeQuery = '';

    if (search !== '') {
      searchQuery = `&title=${search}`;
    }

    if (status !== '') {
      statusQuery = `&status=${status}`;
    }

    if (category !== '') {
      categoryQuery = `&category=${category}`;
    }

    if (articleType !== '') {
      articleTypeQuery = `&articleType=${articleType}`;
    }

    return API_URL.get(
      `admin/v1/articles${queryString}${searchQuery}${statusQuery}${categoryQuery}${articleTypeQuery}`
    );
  };

  const articleDetail = id => {
    return API_URL.get(`admin/v1/articles/${id}`);
  };

  const createArticle = obj => {
    const datafile = obj.imageUrl;
    const dataTagId = obj.tagId;
    const formData = new FormData();
    formData.append('title', obj.title);
    formData.append('duration', 0);
    formData.append('descriptions', obj.descriptions);
    formData.append('categoryId', obj.categoryId);
    formData.append('videoUrl', obj.videoUrl);
    formData.append('publishedDate', obj.publishedDate);
    formData.append('status', obj.status);
    formData.append('articleType', obj.articleType);
    for (var i = 0; i < datafile.length; i++) {
      formData.append('image', datafile[i]);
    }
    for (var j = 0; j < dataTagId.length; j++) {
      formData.append('tagId[]', dataTagId[j]);
    }
    return API_URL.post('admin/v1/articles', formData);
  };

  const updateArticle = (id, obj) => {
    const dataTagId = obj.tagId;
    const formData = new FormData();
    formData.append('title', obj.title);
    formData.append('duration', 0);
    formData.append('descriptions', obj.descriptions);
    formData.append('categoryId', obj.categoryId);
    formData.append('videoUrl', obj.videoUrl);
    formData.append('publishedDate', obj.publishedDate);
    formData.append('status', obj.status);
    formData.append('articleType', obj.articleType);
    for (var j = 0; j < dataTagId.length; j++) {
      formData.append('tagId[]', dataTagId[j]);
    }
    if (typeof obj.imageUrl === 'object') {
      const datafile = obj.imageUrl;
      for (var i = 0; i < datafile.length; i++) {
        formData.append('image', datafile[i]);
      }
      formData.append('imageUrl', '');
    } else {
      formData.append('imageUrl', obj.imageUrl);
    }
    return API_URL.put(`admin/v1/articles/${id}`, formData);
  };

  const articleRatings = id => {
    return API_URL.get(`admin/v1/articles/rating/${id}`);
  };

  const articleStatistic = ({ articleType = '' }) => {
    let articleTypeQuery = '';

    if (articleType !== '') {
      articleTypeQuery = `?articleType=${articleType}`;
    }

    return API_URL.get(`admin/v1/articles/statistic${articleTypeQuery}`);
  };

  const recipeList = ({
    page = 1,
    limit = 10,
    search = '',
    status = '',
    category = ''
  }) => {
    let queryString = `?page=${page}&limit=${limit}`;
    let searchQuery = '';
    let statusQuery = '';
    let categoryQuery = '';

    if (search !== '') {
      searchQuery = `&title=${search}`;
    }

    if (status !== '') {
      statusQuery = `&status=${status}`;
    }

    if (category !== '') {
      categoryQuery = `&category=${category}`;
    }

    return API_URL.get(
      `admin/v1/recipes${queryString}${searchQuery}${statusQuery}${categoryQuery}`
    );
  };

  const recipeDetail = id => {
    return API_URL.get(`admin/v1/recipes/${id}`);
  };

  const createRecipe = obj => {
    const datafile = obj.imageUrl;
    const dataTagId = obj.tagId;
    const formData = new FormData();
    formData.append('title', obj.title);
    formData.append('duration', 0);
    formData.append('recipes', obj.recipes);
    formData.append('categoryId', obj.categoryId);
    formData.append('publishedDate', obj.publishedDate);
    formData.append('status', obj.status);
    for (var i = 0; i < datafile.length; i++) {
      formData.append('image', datafile[i]);
    }
    for (var j = 0; j < dataTagId.length; j++) {
      formData.append('tagId[]', dataTagId[j]);
    }
    return API_URL.post('admin/v1/recipes', formData);
  };

  const updateRecipe = (id, obj) => {
    const dataTagId = obj.tagId;
    const formData = new FormData();
    formData.append('title', obj.title);
    formData.append('duration', 0);
    formData.append('recipes', obj.recipes);
    formData.append('categoryId', obj.categoryId);
    formData.append('publishedDate', obj.publishedDate);
    formData.append('status', obj.status);
    for (var j = 0; j < dataTagId.length; j++) {
      formData.append('tagIds[]', dataTagId[j]);
    }
    if (typeof obj.imageUrl === 'object') {
      const datafile = obj.imageUrl;
      for (var i = 0; i < datafile.length; i++) {
        formData.append('image', datafile[i]);
      }
      formData.append('imageUrl', '');
    } else {
      formData.append('imageUrl', obj.imageUrl);
    }

    return API_URL.put(`admin/v1/recipes/${id}`, formData);
  };

  const generalUserTrends = (page = 1, limit = 10) => {
    return API_URL.get(
      `admin/v1/dashboards/general-user-trends?page=${page}&limit=${limit}`
    );
  };

  const averageUserPerformance = period => {
    return API_URL.get(
      `admin/v1/dashboards/average-user-performance?periodDay=${period}`
    );
  };

  const generalContentAnalysis = (page = 1, limit = 10) => {
    return API_URL.get(
      `admin/v1/dashboards/general-content-analysis?page=${page}&limit=${limit}`
    );
  };

  const generalBusinessAnalysis = (page = 1, limit = 10) => {
    return API_URL.get(
      `admin/v1/dashboards/general-business-analysis?page=${page}&limit=${limit}`
    );
  };

  const foodList = ({ page, limit, keyword, isRated }) => {
    let queryString = `?page=${page}&limit=${limit}`;
    let keywordQuery = '';
    let isRatedQuery = '';

    if (keyword !== '') {
      keywordQuery = `&keyword=${keyword}`;
    }

    if (isRated !== '') {
      isRatedQuery = `&isRated=${isRated}`;
    }

    return API_URL.get(
      `admin/v1/food-logs${queryString}${keywordQuery}${isRatedQuery}`
    );
  };

  const foodDetail = id => {
    return API_URL.get(`admin/v1/food-logs/${id}`);
  };

  const updateFood = (id, obj) => {
    return API_URL.put(`admin/v1/food-logs/${id}/rate`, obj);
  };

  const coachScheduleInterval = ({ sinceDate = '', coachId = '' }) => {
    let queryString = '';

    if (sinceDate !== '') {
      queryString = `?sinceDate=${sinceDate}`;
    }

    if (coachId !== '') {
      queryString = `?coachId=${coachId}`;
    }

    if (sinceDate !== '' && coachId !== '') {
      queryString = `?sinceDate=${sinceDate}&coachId=${coachId}`;
    }

    return API_URL.get(`admin/v1/coach-schedule-intervals${queryString}`);
  };

  const coachScheduleIntervalList = ({ id = '' }) => {
    return API_URL.get(
      `admin/v1/coach-schedules?coachScheduleIntervalId=${id}`
    );
  };

  const bulkCreateCoachSchedules = obj => {
    return API_URL.post('admin/v1/coach-schedules/bulk-create', obj);
  };

  const bulkEditCoachSchedules = obj => {
    return API_URL.put('admin/v1/coach-schedules/bulk-update', obj);
  };

  const patientList = ({ page = 1, limit = 10, keyword = '' }) => {
    let queryString = `?page=${page}&limit=${limit}`;

    if (keyword !== '') {
      queryString = `?page=${page}&limit=${limit}&keyword=${keyword}`;
    }

    return API_URL.get(`admin/v1/users${queryString}`);
  };

  const patientDetail = id => {
    return API_URL.get(`admin/v1/users/${id}`);
  };

  const patientReadArticle = (id, page, limit) => {
    return API_URL.get(
      `admin/v1/users/${id}/read-articles?page=${page}&limit=${limit}`
    );
  };

  const patientPerformance = (id, period = 7, page = 1) => {
    return API_URL.get(
      `admin/v2/users/${id}/performance?periodDay=${period}&prevPage=${page}`
    );
  };

  const generalPerformance = ({
    id,
    bgLoggingConditionTestId = '',
    foodLoggingCategory = '',
    bgLoggingStatusConditionTestId = ''
  }) => {
    return API_URL.get(
      `admin/v1/users/${id}/general-performance?bgLoggingConditionTestId=${bgLoggingConditionTestId}&foodLoggingCategory=${foodLoggingCategory}&bgLoggingStatusConditionTestId=${bgLoggingStatusConditionTestId}`
    );
  };

  const createPatient = obj => {
    const dataObj = {
      name: obj?.name,
      email: obj?.email,
      password: obj?.password,
      role: obj?.role,
      isActive: obj?.isActive
    };
    return API_URL.post('admin/v1/admins', dataObj);
  };

  const updatePatient = (id, obj) => {
    const formData = new FormData();
    formData.append('name', obj.nama);
    formData.append('email', obj.email);
    formData.append('partnerCode', obj.kodePerusahaan);
    formData.append('countryCode', obj.countryCode);
    formData.append('phoneNumber', obj.nomorTelepon);
    formData.append('dateOfBirth', obj.tanggalLahir);
    formData.append('gender', obj.jenisKelamin);
    formData.append('height', obj.tinggiBadan);
    formData.append('weight', obj.beratBadan);
    formData.append('hba1c', obj.hba1cTerakhir);
    formData.append('diabeticDiagnosis', obj.waktuDiagnosaDiabetes);
    formData.append('obstacleCondition', obj.kesulitanMenjagaKondisi);
    formData.append('improveLifestyle', obj.areaYangDitingkatkan);
    formData.append('diabetesMedication', obj.pengobatan);
    if (typeof obj.imageUrl === 'object') {
      const datafile = obj.imageUrl;
      for (var i = 0; i < datafile.length; i++) {
        formData.append('image', datafile[i]);
      }
      formData.append('imageUrl', '');
    } else {
      formData.append('imageUrl', obj.imageUrl);
    }
    return API_URL.put(`admin/v1/users/${id}`, formData);
  };

  const patientCoachingHistory = ({
    id,
    page = 1,
    limit = 10,
    trigger = '',
    date = ''
  }) => {
    let queryString = `?page=${page}&limit=${limit}`;
    let triggerQuery = '';
    let dateQuery = '';

    if (trigger !== '') {
      triggerQuery = `&trigger=${trigger}`;
    }

    if (date !== '') {
      dateQuery = `&date=${date}`;
    }

    return API_URL.get(
      `admin/v1/users/${id}/coaching-history${queryString}${triggerQuery}${dateQuery}`
    );
  };

  const patientLoggingActivity = ({ id, page = 1, limit = 10, type = '' }) => {
    let queryString = `?page=${page}&limit=${limit}`;

    if (type !== '') {
      queryString = `?page=${page}&limit=${limit}&type[]=${type}`;
    }

    return API_URL.get(`admin/v1/users/${id}/logging-activity${queryString}`);
  };

  const programList = () => {
    return API_URL.get('admin/v1/programs');
  };

  const programArticleDetail = id => {
    return API_URL.get(`admin/v1/programs/program-articles/articles/${id}`);
  };

  const programArticleList = id => {
    return API_URL.get(`admin/v1/programs/program-articles/${id}`);
  };

  const updateOrderProgram = obj => {
    return API_URL.put('admin/v1/programs/program-articles-order', obj);
  };

  const createArticleProgram = obj => {
    const datafile = obj.imageUrl;
    const dataTagId = obj.tagId;
    const formData = new FormData();
    formData.append('title', obj.title);
    formData.append('descriptions', obj.descriptions);
    formData.append('categoryId', obj.categoryId);
    formData.append('videoUrl', obj.videoUrl);
    formData.append('programDurationId', obj.programId);
    formData.append('duration', obj.duration);
    formData.append('articleType', obj.articleType);
    formData.append('programId', obj.programId);
    for (var i = 0; i < datafile.length; i++) {
      formData.append('image', datafile[i]);
    }
    for (var j = 0; j < dataTagId.length; j++) {
      formData.append('tagId[]', dataTagId[j]);
    }
    return API_URL.post('admin/v1/programs/program-articles', formData);
  };

  const updateArticleProgram = (id, obj) => {
    const dataTagId = obj.tagId;
    const formData = new FormData();
    formData.append('title', obj.title);
    formData.append('descriptions', obj.descriptions);
    formData.append('categoryId', obj.categoryId);
    formData.append('videoUrl', obj.videoUrl);
    formData.append('programDurationId', obj.programId);
    formData.append('duration', obj.duration);
    formData.append('articleType', obj.articleType);
    formData.append('programId', obj.programId);
    for (var j = 0; j < dataTagId.length; j++) {
      formData.append('tagId[]', dataTagId[j]);
    }
    if (typeof obj.imageUrl === 'object') {
      const datafile = obj.imageUrl;
      for (var i = 0; i < datafile.length; i++) {
        formData.append('image', datafile[i]);
      }
      formData.append('imageUrl', '');
    } else {
      formData.append('imageUrl', obj.imageUrl);
    }
    return API_URL.put(`admin/v1/programs/program-articles/${id}`, formData);
  };

  const deleteArticleProgram = id => {
    return API_URL.delete(`admin/v1/programs/program-articles/${id}`);
  };

  const deliveryList = ({
    page = 1,
    limit = 10,
    keyword = '',
    deliveryStatus = '',
    deliveryDate = ''
  }) => {
    let queryString = `?page=${page}&limit=${limit}`;
    let searchQuery = '';
    let statusQuery = '';
    let deliveryDateQuery = '';

    if (keyword !== '') {
      searchQuery = `&keyword=${keyword}`;
    }

    if (deliveryStatus !== '') {
      statusQuery = `&status=${deliveryStatus}`;
    }

    if (deliveryDate !== '') {
      deliveryDateQuery = `&deliveryDate=${deliveryDate}`;
    }

    return API_URL.get(
      `admin/v1/shipments${queryString}${searchQuery}${statusQuery}${deliveryDateQuery}`
    );
  };

  const deliveryDetail = id => {
    return API_URL.get(`admin/v1/shipments/${id}`);
  };

  const createDelivery = obj => {
    return API_URL.post('admin/v1/shipments', obj);
  };

  const updateDelivery = (id, obj) => {
    return API_URL.put(`admin/v1/shipments/${id}`, obj);
  };

  const updateDeliveryStatus = (id, obj) => {
    return API_URL.put(`admin/v1/shipments/status/${id}`, obj);
  };

  const userList = ({ page = 1, limit = 10, search = '' }) => {
    let queryString = `?page=${page}&limit=${limit}`;
    let searchQuery = '';

    if (search !== '') {
      searchQuery = `&keyword=${search}`;
    }

    return API_URL.get(`admin/v1/users${queryString}${searchQuery}`);
  };

  const userDetail = id => {
    return API_URL.get(`admin/v1/users/${id}`);
  };

  const consultingList = ({
    page = 1,
    limit = 10,
    date = '2023-01-05',
    utcOffset = '+7',
    status = '',
    coachId = '',
    trigger = ''
  }) => {
    let queryString = `?page=${page}&limit=${limit}&date=${date}&utcOffset=${utcOffset}&status=${status}&coachId=${coachId}&trigger=${trigger}`;
    return API_URL.get(`admin/v1/consultations${queryString}`);
  };

  const totalConsultationList = ({ month = '2023-01', status = '' }) => {
    let queryString = '';

    if (month !== '') {
      queryString = `?month=${month}`;
    }
    if (status !== '') {
      queryString = `?month=${month}&status=${status}`;
    }
    return API_URL.get(
      `admin/v1/dashboards/total-data${queryString}&trigger=user`
    );
  };

  const qiscusJWT = () => {
    return API_URL.get('admin/v1/auth/qiscus-token');
  };

  const consultationSummary = (id, obj) => {
    return API_URL.post(`admin/v1/chat-rooms/${id}/summary`, obj);
  };

  const consultationComplete = id => {
    return API_URL.put(`admin/v1/consultations/${id}/complete`);
  };

  const notifications = ({ page = 1, limit = 10 }) => {
    return API_URL.get(`admin/v1/notifications?page=${page}&limit=${limit}`);
  };

  const readNotifications = id => {
    return API_URL.get(`admin/v1/notifications/${id}`);
  };

  const updateNotificationToken = obj => {
    return API_URL.put(`admin/v1/notifications/token`, obj);
  };

  const coachList = ({ role = '' }) => {
    return API_URL.get(`admin/v1/admins?role=${role}`);
  };

  const getAllArticleList = ({ page = 1, search = '' }) => {
    return API_URL.get(
      `admin/v1/articles?page=${page}&title=${search}&status=publish`
    );
  };

  const conditionTest = () => {
    return API_URL.get(`admin/v1/blood-glucoses/condition-tests`);
  };

  const chatRoomList = ({ page = 1, limit = 10, keyword = '' }) => {
    let queryString = `?page=${page}&limit=${limit}&keyword=${keyword}`;
    return API_URL.get(`/admin/v1/chat-rooms${queryString}`);
  };

  const membershipFilterList = type => {
    return API_URL.get(`admin/v1/memberships/filter/${type}`);
  };

  const membershipList = ({
    page,
    limit,
    keyword,
    sortBy,
    sortDirection,
    membershipPackage,
    membershipStatus,
    companyName,
    startDate,
    endDate,
    isB2c
  }) => {
    let query = `${
      isB2c ? 'b2c' : 'b2b'
    }?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;

    if (keyword) {
      query = `${query}&keyword=${keyword}`;
    }

    if (isB2c && membershipPackage) {
      query = `${query}&membershipPackage=${membershipPackage}`;
    }

    if (!isB2c && companyName) {
      query = `${query}&companyName=${companyName}`;
    }

    if (membershipStatus) {
      query = `${query}&membershipStatus=${membershipStatus}`;
    }
    if (sortBy && sortDirection) {
      query = `${query}&sortBy=${sortBy}&sortDirection=${sortDirection}`;
    }
    return API_URL.get(`admin/v1/memberships/${query}`);
  };

  const membershipCard = ({ start, end }) => {
    return API_URL.get(`admin/v1/memberships?start=${start}&end=${end}`);
  };

  const membershipDetails = member_id => {
    return API_URL.get(`admin/v1/memberships/${member_id}/detail`);
  };

  const getMembershipProfile = member_id => {
    return API_URL.get(`admin/v1/memberships/${member_id}/edit`);
  };

  const getCancelationTransactionHistoryFilter = type => {
    return API_URL.get(`admin/v1/memberships/${type}/filter`);
  };

  const getTransactionHistories = ({
    page,
    limit,
    member_id,
    sortBy,
    sortDirection,
    membershipPackage,
    startDate,
    endDate,
    paymentStatus
  }) => {
    let query = `${member_id}/transactions?page=${page}&limit=${limit}`;

    if (sortBy && sortDirection) {
      query = `${query}&sortBy=${sortBy}&sortDirection=${sortDirection}`;
    }

    if (membershipPackage) {
      query = `${query}&membershipPackage=${membershipPackage}`;
    }

    if (paymentStatus) {
      query = `${query}&paymentStatus=${paymentStatus}`;
    }

    if (startDate && endDate) {
      query = `${query}&startDate=${startDate}&endDate=${endDate}`;
    }

    return API_URL.get(`admin/v1/memberships/${query}`);
  };

  const getCancelationHistories = ({
    page,
    limit,
    member_id,
    cancelationType,
    sortBy,
    sortDirection,
    startDate,
    endDate
  }) => {
    let query = `${member_id}/cancelations?page=${page}&limit=${limit}`;

    if (sortBy && sortDirection) {
      query = `${query}&sortBy=${sortBy}&sortDirection=${sortDirection}`;
    }

    if (cancelationType) {
      query = `${query}&cancelationType=${cancelationType}`;
    }

    if (startDate && endDate) {
      query = `${query}&startDate=${startDate}&endDate=${endDate}`;
    }

    return API_URL.get(`admin/v1/memberships/${query}`);
  };

  const getStories = ({
    page,
    limit,
    startDate,
    endDate,
    keyword,
    sort,
    category,
    status
  }) => {
    let query = `page=${page}&limit=${limit}`;

    if (startDate && endDate) {
      query = `${query}&startDate=${startDate}&endDate=${endDate}`;
    }
    if (keyword) {
      query = `${query}&keyword=${keyword}`;
    }

    if (sort.sortBy && sort.sortDirection) {
      query = `${query}&sortBy=${sort.sortBy}&sortDirection=${sort.sortDirection}`;
    }

    if (category) {
      query = `${query}&category=${category}`;
    }

    if (status) {
      query = `${query}&status=${status}`;
    }
    return API_URL.get(`admin/v1/stories?${query}`);
  };

  const getStoriesByType = ({ page, limit, keyword, type }) => {
    let query = `page=${page}&limit=${limit}`;

    if (keyword) {
      query = `${query}&keyword=${keyword}`;
    }

    return API_URL.get(`admin/v1/stories/${type}?${query}`);
  };

  const getStoryFilterOption = () => {
    return API_URL.get(`admin/v1/stories/filters`);
  };

  const reOrderStory = ({ type, story_id, direction }) => {
    return API_URL.put(
      `admin/v1/stories/${type}/reorder/${story_id}/${direction}`
    );
  };

  const editMembershipData = (req, member_id) => {
    return API_URL.post(`admin/v1/memberships/${member_id}/edit`, req);
  };

  const editBulkMembershipData = req => {
    return API_URL.post(`admin/v1/memberships/edit/bulk`, req);
  };

  const storiesOptionFilter = () => {
    return API_URL.get(`admin/v1/stories/dropdowns`);
  };

  const getStoryDetails = story_id => {
    return API_URL.get(`admin/v1/stories/${story_id}/detail`);
  };

  const createStories = obj => {
    return API_URL.post(`admin/v1/stories`, obj);
  };

  const editStories = (story_id, obj) => {
    return API_URL.put(`admin/v1/stories/${story_id}`, obj);
  };

  const deleteStories = story_id => {
    return API_URL.delete(`admin/v1/stories/${story_id}`);
  };

  const deleteAccounts = token => {
    return API_URL.delete(`account/delete?token=${token}`);
  };

  const deletePartner = id => {
    return API_URL.delete(`admin/v1/partners/members/${id}`);
  };

  return {
    adminList,
    adminDetail,
    createAdmin,
    updateAdmin,
    updatePassword,
    deleteAdmin,
    partnerList,
    partnerDetail,
    createPartner,
    updatePartner,
    partnerMemberList,
    sendEmailMember,
    resendEmailMember,
    tagList,
    tagDetail,
    createTag,
    updateTag,
    deleteTag,
    activityList,
    activityDetail,
    createActivity,
    updateActivity,
    deleteActivity,
    categoryList,
    categoryDetail,
    createCategory,
    updateCategory,
    deleteCategory,
    configurationDetail,
    updateConfiguration,
    articleList,
    articleDetail,
    createArticle,
    updateArticle,
    articleRatings,
    articleStatistic,
    recipeList,
    recipeDetail,
    createRecipe,
    updateRecipe,
    generalUserTrends,
    averageUserPerformance,
    generalContentAnalysis,
    generalBusinessAnalysis,
    foodList,
    foodDetail,
    updateFood,
    coachScheduleInterval,
    bulkCreateCoachSchedules,
    coachScheduleIntervalList,
    bulkEditCoachSchedules,
    patientList,
    patientDetail,
    patientReadArticle,
    patientPerformance,
    generalPerformance,
    createPatient,
    updatePatient,
    patientCoachingHistory,
    patientLoggingActivity,
    programList,
    programArticleList,
    programArticleDetail,
    updateOrderProgram,
    createArticleProgram,
    updateArticleProgram,
    deleteArticleProgram,
    deliveryList,
    deliveryDetail,
    createDelivery,
    updateDelivery,
    updateDeliveryStatus,
    userList,
    userDetail,
    logout,
    consultingList,
    totalConsultationList,
    qiscusJWT,
    consultationSummary,
    consultationComplete,
    notifications,
    readNotifications,
    updateNotificationToken,
    coachList,
    getAllArticleList,
    conditionTest,
    chatRoomList,
    membershipList,
    membershipFilterList,
    membershipCard,
    membershipDetails,
    getMembershipProfile,
    getCancelationTransactionHistoryFilter,
    getTransactionHistories,
    getCancelationHistories,
    getStories,
    getStoryFilterOption,
    getStoriesByType,
    reOrderStory,
    editMembershipData,
    editBulkMembershipData,
    storiesOptionFilter,
    getStoryDetails,
    createStories,
    editStories,
    deleteStories,
    deleteAccounts,
    deletePartner
  };
};
