import React, { useMemo } from 'react';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { Brand } from '../brand/Brand';
import { AsideMenu } from './aside-menu/AsideMenu';

export function Aside() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      // disableScroll: objectPath.get(uiService.config, 'aside.menu.dropdown') === 'true' || false,
      asideClassesFromConfig: uiService.getClasses('aside', true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        'aside.self.minimize.toggle'
      )
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left d-flex shadow-sm ${layoutProps.asideClassesFromConfig}`}
      >
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto border-0">
          <Brand />
          <div className="w-100">
            <AsideMenu />
          </div>
        </div>
        {/* end::Primary */}
      </div>
      {/* end::Aside */}
    </>
  );
}
