/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as Yup from 'yup';
import { requestForgotPassword } from '@redux/ducks/Auth';

const initialValues = {
  email: ''
};

function ForgotPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Required field!')
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await dispatch(requestForgotPassword(values)).then(() => {
        localStorage.setItem('emailResetPassword', values.email);
        history.push('/auth/verify-otp');
      });
      setSubmitting(false);
    }
  });

  return (
    <div className="login-form login-forgot" style={{ display: 'block' }}>
      <h1 className="text-primary cursor-pointer">
        <ArrowBackIcon
          fontSize="lg"
          onClick={() => history.push('/auth/login')}
        />
      </h1>
      <div className="text-center mb-5 mb-lg-10">
        <h3 className="font-size-h1 mb-5 my-lg-8 font-weight-boldest">
          Lupa Kata Sandi
        </h3>
        <p className="text-dark">
          Masukan email Anda yang terdaftar. Kami akan mengirimkan kode keamanan
          untuk mengganti kata sandi Anda.
        </p>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-boldest">
              {formik.status}
            </div>
          </div>
        )}
        <div className="form-group fv-plugins-icon-container">
          <label>Email</label>
          <input
            placeholder="Masukkan Email"
            type="email"
            className="form-control form-control-solid h-auto py-5 px-6"
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container mt-1">
              <small className="fv-help-block text-danger">
                {formik.errors.email}
              </small>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            id="kt_login_forgot_submit"
            type="submit"
            className="w-100 btn btn-primary font-weight-boldest px-9 py-4 my-3 mx-0"
            disabled={formik.isSubmitting}
          >
            Lanjut
            {formik.isSubmitting && (
              <span className="ml-3 spinner spinner-white"></span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
