/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState, useRef } from 'react';
import objectPath from 'object-path';
import { useDispatch } from 'react-redux';
import { useSubheader } from '@metronic/layout/_core/MetronicSubheader';
import { useHtmlClassService } from '@metronic/layout/_core/MetronicLayout';
import { BreadCrumbs } from './components/BreadCrumbs';
import { useMediaQuery } from '@mui/material';

//Assets
import profileSvg from '@assets/icons/profil-icon.svg';
import bellSvg from '@assets/icons/bell-icon.svg';
import { logout } from '@redux/ducks/Auth';
import { notificationList, readNotification } from '@redux/ducks/Notification';
import { getStorage } from '@metronic/_helpers/LocalStorageHelpers';
import Badge from '@mui/material/Badge';
import { useHistory } from 'react-router-dom';

export function SubHeader() {
  const dispatch = useDispatch();
  const uiService = useHtmlClassService();
  const userData = getStorage();
  const subheader = useSubheader();
  const matches = useMediaQuery('(max-width:767px)');
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNotif, setIsOpenNotif] = useState(false);
  const [notifList, setNotifList] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dropdownRef = useRef(document.getElementById('dropdown_ref'));
  const notificationRef = useRef(document.getElementById('notification_ref'));

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, 'subheader.fixed'),
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        'subheader.mobile-toggle'
      ),
      subheaderCssClasses: uiService.getClasses('subheader', true),
      subheaderContainerCssClasses: uiService.getClasses(
        'subheader_container',
        true
      )
    };
  }, [uiService]);

  const getNotificationList = page => {
    dispatch(notificationList({ page: page, limit: '20' })).then(({ data }) => {
      setUnreadCount(data?.totalUnread || 0);
      setNotifList(prev => [...prev, ...data?.notification]);
    });
  };

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  useEffect(() => {
    if (isOpen) {
      dropdownRef.current.classList.add('dropdown_opened');
    } else {
      dropdownRef.current.classList.remove('dropdown_opened');
    }

    if (isOpenNotif) {
      notificationRef.current.classList.add('dropdown_notification_opened');
    } else {
      notificationRef.current.classList.remove('dropdown_notification_opened');
    }
  }, [isOpen, isOpenNotif]);

  useEffect(() => {
    getNotificationList(currentPage);

    const intervalId = setInterval(() => {
      getNotificationList(currentPage);
    }, 60000);

    return () => clearInterval(intervalId);
  }, [currentPage]);

  const handleMenuScrollToBottom = () => {
    if (notificationRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = notificationRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const handleReadNotification = (id, dates) => {
    const date = new Date(dates);
    dispatch(readNotification(id)).then(() => {
      getNotificationList(currentPage);
      history.push(
        `/coaching?date=${date.getFullYear()}-${date.getMonth() +
          1}-${date.getDate()}`
      );
    });
  };

  return (
    <div
      id="kt_subheader"
      className={`subheader pb-3 ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0`}
      >
        {/* Info */}
        <div
          className={`w-100 mr-1 shadow-xs m-0 ${
            matches
              ? 'd-flex align-items-center'
              : 'd-flex align-items-center flex-wrap justify-content-between'
          }`}
        >
          {/* begin::Mobile Toggle */}
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
              type="button"
            >
              <span />
            </button>
          )}
          {/* end::Mobile Toggle */}

          {/* begin::Heading */}
          {subheader.breadcrumbs.length > 0 && (
            <div
              className={`d-flex align-items-center ${
                matches ? 'p-5' : 'px-10'
              }`}
            >
              <BreadCrumbs
                items={subheader.breadcrumbs}
                newVersion={subheader.newVersion}
              />
              <p className="subheader-title font-weight-boldest my-2 ml-3">
                {subheader.title}
              </p>
            </div>
          )}
          {/* end::Heading */}

          {/* begin::Admin */}
          <div className="d-flex align-items-center ml-auto">
            <div
              className="admin_dropdown"
              onClick={() => setIsOpenNotif(!isOpenNotif)}
            >
              <Badge color="error" badgeContent={unreadCount}>
                <img alt="" src={bellSvg} />
              </Badge>
            </div>

            <div className="admin_dropdown" onClick={() => setIsOpen(!isOpen)}>
              <span className="header_dropdown_menu_text mx-4">
                <span className="text-muted">Hi, </span> {` ${userData?.name}`}
              </span>
              <img alt="" src={profileSvg} />
            </div>
            <div
              ref={dropdownRef}
              id="dropdown_ref"
              className="header_dropdown"
            >
              {/* <div className="header_dropdown_content">
                <span className="header_dropdown_menu_text cursor-pointer text-primary">
                  Profil Saya
                </span>
              </div>
              <div className="mx-5">
                <hr />
              </div> */}
              <div
                className="header_dropdown_content"
                onClick={() => dispatch(logout())}
              >
                <span className="header_dropdown_menu_text cursor-pointer text-primary">
                  Keluar
                </span>
              </div>
            </div>

            <div
              ref={notificationRef}
              id="notification_ref"
              className="notification_dropdown"
              onScroll={() => handleMenuScrollToBottom()}
            >
              {notifList.length > 0 ? (
                notifList.map((item, index) => {
                  return (
                    <>
                      <div
                        className="header_dropdown_notification_content cursor-pointer"
                        onClick={() =>
                          handleReadNotification(item?.id, item?.visibleAt)
                        }
                      >
                        <span className="header_dropdown_notification_text text-primary">
                          {!item?.adminNotification?.read && (
                            <div className="header_dropdown_notification_unread" />
                          )}
                          {item?.title}
                        </span>
                        <p className="header_dropdown_notification_subtitle">
                          {item?.content}
                        </p>
                      </div>
                      {notifList.length !== index + 1 && (
                        <div className="mx-5">
                          <hr />
                        </div>
                      )}
                    </>
                  );
                })
              ) : (
                <div className="header_dropdown_notification_content">
                  <span className="header_dropdown_notification_text text-primary">
                    No Notification
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* end::Admin */}
      </div>
    </div>
  );
}
