/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { login } from '@redux/ducks/Auth';
import OneSignal from 'react-onesignal';

const useStyles = makeStyles(() => ({
  passwordBorder: {
    border: '1px solid #F3F6F9',
    borderRadius: '1rem'
  },
  textDecorationUnderline: {
    textDecoration: 'underline'
  }
}));

function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [playerId, setPlayerId] = useState('');
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Required field'),
    password: Yup.string()
      .min(6, 'Minimum 6 characters')
      .max(50, 'Maximum 50 characters')
      .required('Required field')
  });

  useEffect(() => {
    OneSignal.getUserId(userId => {
      setPlayerId(userId);
    });
  }, []);

  const passwordIcon = () => {
    if (isShowPassword) return <VisibilityOffIcon />;
    return <RemoveRedEyeIcon />;
  };

  const loginUser = values => {
    dispatch(login(values)).then(({ data }) => {
      if (data?.admin?.role === 'Admin') {
        setTimeout(() => {
          window.location.href = '/business-analytic';
        }, 200);
        return;
      }
      setTimeout(() => {
        window.location.href = '/';
      }, 200);
      return;
    });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: values => {
      values.notificationToken = playerId;
      loginUser(values);
    }
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-5 mb-lg-10">
        <h3 className="font-size-h1 mb-5 mb-lg-8 font-weight-boldest">
          Sign In
        </h3>
        <p className="text-dark">
          Masukkan email dan password Anda untuk masuk.
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <label>Email</label>
          <input
            placeholder="Masukkan Email"
            type="email"
            className="form-control form-control-solid h-auto py-5 px-6"
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container mt-1">
              <small className="fv-help-block text-danger">
                {formik.errors.email}
              </small>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label>Kata Sandi</label>
          <div className={`${classes.passwordBorder} input-group`}>
            <input
              placeholder="Masukkan Kata Sandi"
              type={isShowPassword ? 'text' : 'password'}
              className="form-control form-control-solid h-auto py-5 px-6 border-0"
              name="password"
              {...formik.getFieldProps('password')}
            />
            <div
              className="input-group-addon d-flex align-items-center p-2 cursor-pointer"
              onClick={() => setIsShowPassword(!isShowPassword)}
            >
              {passwordIcon()}
            </div>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container mt-1">
              <small className="fv-help-block text-danger">
                {formik.errors.password}
              </small>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={loading}
            className=" w-100 btn btn-primary font-weight-boldest px-9 py-4 my-3"
          >
            <span>Masuk</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <div className="text-center w-100 mt-5">
            <Link
              to="/auth/forgot-password"
              className={`${classes.textDecorationUnderline} text-primary my-3 mr-2 font-weight-boldest`}
              id="kt_login_forgot"
            >
              Lupa Kata Sandi
            </Link>
          </div>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default Login;
