import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const RecipeManagementList = loadable(
  () =>
    import(
      /* webpackChunkName: "recipe-management-list" */ '@routes/RecipeManagement/pages/RecipeList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
const RecipeManagementCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "recipe-management-create" */ '@routes/RecipeManagement/pages/NewRecipe/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
const RecipeManagementDetail = loadable(
  () =>
    import(
      /* webpackChunkName: "recipe-management-update" */ '@routes/RecipeManagement/pages/RecipeDetail/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
const RecipeManagementEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "recipe-management-edit" */ '@routes/RecipeManagement/pages/EditRecipe/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/recipe-management"
        permission={['Admin']}
        component={RecipeManagementList}
      />
      <ContentRoute
        exact
        path="/recipe-management/create"
        permission={['Admin']}
        component={RecipeManagementCreate}
      />
      <ContentRoute
        exact
        path="/recipe-management/detail/:id"
        permission={['Admin']}
        component={RecipeManagementDetail}
      />
      <ContentRoute
        exact
        path="/recipe-management/edit/:id"
        permission={['Admin']}
        component={RecipeManagementEdit}
      />
    </>
  );
};
