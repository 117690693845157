/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { resetPassword } from '@redux/ducks/Auth';

const useStyles = makeStyles(() => ({
  passwordBorder: {
    border: '1px solid #F3F6F9',
    borderRadius: '1rem'
  },
  textDecorationUnderline: {
    textDecoration: 'underline'
  }
}));

function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.getItem('resetPasswordToken');
  const [loading, setLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const LoginSchema = Yup.object().shape({
    confirmPassword: Yup.string()
      .min(6, 'Minimum 6 characters')
      .max(50, 'Maximum 50 characters')
      .oneOf([Yup.ref('password'), null], 'Password must match')
      .required('Required field'),
    password: Yup.string()
      .min(6, 'Minimum 6 characters')
      .max(50, 'Maximum 50 characters')
      .required('Required field')
  });

  const passwordIcon = () => {
    if (isShowPassword) return <VisibilityOffIcon />;
    return <RemoveRedEyeIcon />;
  };

  const confirmPasswordIcon = () => {
    if (isShowConfirmPassword) return <VisibilityOffIcon />;
    return <RemoveRedEyeIcon />;
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      dispatch(
        resetPassword({
          password: values.password,
          resetPasswordToken: token
        })
      ).then(() => {
        setLoading(false);
        localStorage.removeItem('resetPasswordToken');
        history.push('/auth/login');
      });
      setSubmitting(false);
    }
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <h1 className="text-primary cursor-pointer">
        <ArrowBackIcon
          fontSize="lg"
          onClick={() => history.push('/auth/login')}
        />
      </h1>
      <div className="text-center mb-5 mb-lg-10">
        <h3 className="font-size-h1 mb-5 my-lg-8 font-weight-boldest">
          Ganti Kata Sandi
        </h3>
        <p className="text-dark">Masukkan kata sandi baru Anda</p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <label>Kata Sandi Baru</label>
          <div className={`${classes.passwordBorder} input-group`}>
            <input
              placeholder="Masukkan Kata Sandi"
              type={isShowPassword ? 'text' : 'password'}
              className="form-control form-control-solid h-auto py-5 px-6 border-0"
              name="password"
              {...formik.getFieldProps('password')}
            />
            <div
              className="input-group-addon d-flex align-items-center p-2 cursor-pointer"
              onClick={() => setIsShowPassword(!isShowPassword)}
            >
              {passwordIcon()}
            </div>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container mt-1">
              <small className="fv-help-block text-danger">
                {formik.errors.password}
              </small>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label>Konfirmasi Kata Sandi Baru</label>
          <div className={`${classes.passwordBorder} input-group`}>
            <input
              placeholder="Masukkan Kata Sandi"
              type={isShowConfirmPassword ? 'text' : 'password'}
              className="form-control form-control-solid h-auto py-5 px-6 border-0"
              name="confirmPassword"
              {...formik.getFieldProps('confirmPassword')}
            />
            <div
              className="input-group-addon d-flex align-items-center p-2 cursor-pointer"
              onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
            >
              {confirmPasswordIcon()}
            </div>
          </div>
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="fv-plugins-message-container mt-1">
              <small className="fv-help-block text-danger">
                {formik.errors.confirmPassword}
              </small>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={!token || (loading && true)}
            className=" w-100 btn btn-primary font-weight-boldest px-9 py-4 my-3"
          >
            <span>Simpan</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default Login;
