import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const PartnerManagementList = loadable(
  () =>
    import(
      /* webpackChunkName: "partner-management-list" */ '@routes/PartnerManagement/pages/PartnerList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
const PartnerManagementCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "partner-management-create" */ '@routes/PartnerManagement/pages/NewPartner/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
const PartnerManagementUpdate = loadable(
  () =>
    import(
      /* webpackChunkName: "partner-management-update" */ '@routes/PartnerManagement/pages/PartnerDetail/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/partner-management"
        permission={['Admin']}
        component={PartnerManagementList}
      />
      <ContentRoute
        exact
        path="/partner-management/create"
        permission={['Admin']}
        component={PartnerManagementCreate}
      />
      <ContentRoute
        exact
        path="/partner-management/edit/:id"
        permission={['Admin']}
        component={PartnerManagementUpdate}
      />
    </>
  );
};
