import OneSignal from 'react-onesignal';
import { updateNotification } from '@redux/ducks/Notification';
import { useDispatch } from 'react-redux';

export default async function runOneSignal() {
  const ONESIGNAL_KEY = process.env.ONESIGNAL_KEY;
  await OneSignal.init({
    appId: ONESIGNAL_KEY,
    safari_web_id: 'web.onesignal.auto.21eb64f1-a307-4b53-9fa9-5af0b410a31b',
    allowLocalhostAsSecureOrigin: true
  });

  OneSignal.showSlidedownPrompt({ force: true });

  OneSignal.on('subscriptionChange', async isSubscribed => {
    const dispatch = useDispatch();
    let playerId = '';
    if (isSubscribed) {
      try {
        playerId = await OneSignal.getUserId();
        dispatch(updateNotification({ notificationToken: playerId }));
      } catch (error) {
        console.error('Error getting OneSignal player ID:', error);
      }
    }
  });
}
