import React from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_helpers';

export function Brand() {
  return (
    <>
      {/* begin::Brand */}
      <div className="w-100 aside-brand d-flex flex-column align-items-start flex-column-auto py-6 pl-6">
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          <img
            alt="logo"
            src={toAbsoluteUrl('/media/bg/loop-health.svg')}
            className="max-h-35px"
          />
        </Link>
        {/* end::Logo */}
      </div>
      {/* end::Brand */}
    </>
  );
}
