import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const BusinessAnalyticList = loadable(
  () =>
    import(
      /* webpackChunkName: "business-analytic-list" */ '@routes/BusinessAnalytic/pages/BusinessAnalyticList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);

export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/business-analytic"
        permission={['Admin']}
        component={BusinessAnalyticList}
      />
    </>
  );
};
