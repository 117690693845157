import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OtpInput from 'react-otp-input';
import { verifyOtp } from '@redux/ducks/Auth';

function OtpPassword() {
  const history = useHistory();
  const email = localStorage.getItem('emailResetPassword');
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const [inputValue, setInputValue] = useState('');

  return (
    <div className="login-form login-forgot" style={{ display: 'block' }}>
      <h1 className="text-primary cursor-pointer">
        <ArrowBackIcon
          fontSize="lg"
          onClick={() => history.push('/auth/login')}
        />
      </h1>
      <div className="text-center mb-5 mb-lg-10">
        <h3 className="font-size-h1 mb-5 my-lg-8 font-weight-boldest">
          Masukkan Kode Keamanan
        </h3>
        <p className="text-dark">
          Masukan kode keamanan yang dikirimkan ke email Anda.
        </p>
      </div>
      <form>
        <OtpInput
          value={inputValue}
          onChange={otp => setInputValue(otp)}
          containerStyle="d-flex justify-content-between align-items-center"
          focusStyle={{ outline: '2px solid #313E71' }}
          numInputs={6}
          isInputNum
          isInputSecure
          inputStyle={{
            width: '48px',
            height: '48px',
            borderRadius: '7px',
            border: '1px solid #E0E9F1'
          }}
        />
        {inputValue.length !== 6 && isError && (
          <div className="fv-plugins-message-container mt-1">
            <small className="fv-help-block text-danger">Required field!</small>
          </div>
        )}
        <div className="form-group d-flex flex-wrap flex-center mt-5 mt-lg-10">
          <button
            id="kt_login_forgot_submit"
            type="submit"
            disabled={!email && true}
            className="w-100 btn btn-primary font-weight-boldest px-9 py-4 my-3 mx-0"
            onClick={e => {
              e.preventDefault();
              if (inputValue.length === 6 && email) {
                dispatch(verifyOtp({ email, OTP: inputValue })).then(
                  ({ data }) => {
                    localStorage.removeItem('emailResetPassword');
                    localStorage.setItem(
                      'resetPasswordToken',
                      data?.resetPasswordToken
                    );
                    history.push('/auth/reset-password');
                  }
                );
                setIsError(false);
                return;
              }
              setIsError(true);
            }}
          >
            Lanjut
          </button>
        </div>
      </form>
    </div>
  );
}

export default OtpPassword;
