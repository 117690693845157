import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from '@components/ErrorBoundary';
import ContextProvider from '@context';
import Routes from '@route-gateway';
import ErrorsPage from '@metronic/modules/ErrorsExamples/ErrorsPage';
import { MaterialThemeProvider } from '@metronic/layout';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SWRConfig } from 'swr';
import runOneSignal from './OneSignal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const fetcher = (resource, init) =>
  fetch(resource, init).then(res => res.json());

function App() {
  useEffect(() => {
    runOneSignal();
  });
  return (
    <ErrorBoundary render={() => <ErrorsPage />}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SWRConfig
          value={{ errorRetryCount: 2, errorRetryInterval: 2000, fetcher }}
        >
          <MaterialThemeProvider>
            <ContextProvider>
              <Router>
                <Routes />
              </Router>
            </ContextProvider>
          </MaterialThemeProvider>
        </SWRConfig>
      </LocalizationProvider>
    </ErrorBoundary>
  );
}

export default App;
