import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const MasterDataActivityList = loadable(
  () =>
    import(
      /* webpackChunkName: "activity-management-list" */ '@routes/MasterDataActivity/pages/ActivityList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
const MasterDataActivityCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "activity-management-create" */ '@routes/MasterDataActivity/pages/NewActivity/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
const MasterDataActivityUpdate = loadable(
  () =>
    import(
      /* webpackChunkName: "activity-management-update" */ '@routes/MasterDataActivity/pages/ActivityDetail/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/activity-management"
        permission={['Admin']}
        component={MasterDataActivityList}
      />
      <ContentRoute
        exact
        path="/activity-management/create"
        permission={['Admin']}
        component={MasterDataActivityCreate}
      />
      <ContentRoute
        exact
        path="/activity-management/detail/:id"
        permission={['Admin']}
        component={MasterDataActivityUpdate}
      />
    </>
  );
};
