import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const FoodManagementList = loadable(
  () =>
    import(
      /* webpackChunkName: "food-management-list" */ '@routes/FoodManagement/pages/FoodList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
const FoodManagementUpdate = loadable(
  () =>
    import(
      /* webpackChunkName: "food-management-update" */ '@routes/FoodManagement/pages/FoodDetail/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/food-management"
        permission={['Admin', 'Coach']}
        component={FoodManagementList}
      />
      <ContentRoute
        exact
        path="/food-management/edit/:id"
        permission={['Admin', 'Coach']}
        component={FoodManagementUpdate}
      />
    </>
  );
};
