import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const DeliveryManagementList = loadable(
  () =>
    import(
      /* webpackChunkName: "delivery-management-list" */ '@routes/DeliveryManagement/pages/DeliveryList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
const DeliveryManagementCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "delivery-management-create" */ '@routes/DeliveryManagement/pages/NewDelivery/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
const DeliveryManagementDetail = loadable(
  () =>
    import(
      /* webpackChunkName: "delivery-management-update" */ '@routes/DeliveryManagement/pages/DeliveryDetail/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/delivery-management"
        permission={['Admin']}
        component={DeliveryManagementList}
      />
      <ContentRoute
        exact
        path="/delivery-management/create"
        permission={['Admin']}
        component={DeliveryManagementCreate}
      />
      <ContentRoute
        exact
        path="/delivery-management/detail/:id"
        permission={['Admin']}
        component={DeliveryManagementDetail}
      />
    </>
  );
};
