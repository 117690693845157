import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const MasterDataConfigurationDetail = loadable(
  () =>
    import(
      /* webpackChunkName: "configuration-management-update" */ '@routes/MasterDataConfiguration/pages/ConfigurationDetail/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/configuration-management"
        permission={['Admin']}
        component={MasterDataConfigurationDetail}
      />
    </>
  );
};
