import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const AdminManagementList = loadable(
  () =>
    import(
      /* webpackChunkName: "admin-management-list" */ '@routes/AdminManagement/pages/AdminList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
const AdminManagementCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "admin-management-create" */ '@routes/AdminManagement/pages/NewAdmin/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
const AdminManagementUpdate = loadable(
  () =>
    import(
      /* webpackChunkName: "admin-management-update" */ '@routes/AdminManagement/pages/AdminDetail/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);

const AdminManagementUpdatePassword = loadable(
  () =>
    import(
      /* webpackChunkName: "admin-management-update-password" */ '@routes/AdminManagement/pages/AdminUpdatePassword/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/admin-management"
        permission={['Admin']}
        component={AdminManagementList}
      />
      <ContentRoute
        exact
        path="/admin-management/create"
        permission={['Admin']}
        component={AdminManagementCreate}
      />
      <ContentRoute
        exact
        path="/admin-management/edit/:id"
        permission={['Admin']}
        component={AdminManagementUpdate}
      />
      <ContentRoute
        exact
        path="/admin-management/change-password/:id"
        permission={['Admin']}
        component={AdminManagementUpdatePassword}
      />
    </>
  );
};
