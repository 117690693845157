import React from 'react';
// import { AuthProvider } from '@context/auth';
import { ToasterProvider } from '@context/toaster';
import { LocaleProvider } from '@context/locale';
import { node } from 'prop-types';

function ContextProvider({ children }) {
  return (
    // <AuthProvider>
    <LocaleProvider>
      <ToasterProvider>{children}</ToasterProvider>
    </LocaleProvider>
    // </AuthProvider>
  );
}

ContextProvider.propTypes = {
  children: node.isRequired
};

export default ContextProvider;
