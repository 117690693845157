import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const CoachingList = loadable(
  () =>
    import(
      /* webpackChunkName: "coaching-list" */ '@routes/Coaching/pages/CoachingList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);

export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/coaching"
        permission={['Admin', 'Coach']}
        component={CoachingList}
      />
    </>
  );
};
