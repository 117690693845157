import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const CoachingSession = loadable(
  () =>
    import(
      /* webpackChunkName: "coaching-session" */ '@routes/CoachingSession/pages/CoachingSession/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);

export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/coaching-session"
        permission={['Admin', 'Coach']}
        component={CoachingSession}
      />
    </>
  );
};
