import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const MasterDataCategoryList = loadable(
  () =>
    import(
      /* webpackChunkName: "category-management-list" */ '@routes/MasterDataCategory/pages/CategoryList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
const MasterDataCategoryCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "category-management-create" */ '@routes/MasterDataCategory/pages/NewCategory/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
const MasterDataCategoryDetail = loadable(
  () =>
    import(
      /* webpackChunkName: "category-management-update" */ '@routes/MasterDataCategory/pages/CategoryDetail/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/category-management"
        permission={['Admin']}
        component={MasterDataCategoryList}
      />
      <ContentRoute
        exact
        path="/category-management/create"
        permission={['Admin']}
        component={MasterDataCategoryCreate}
      />
      <ContentRoute
        exact
        path="/category-management/detail/:id/:category"
        permission={['Admin']}
        component={MasterDataCategoryDetail}
      />
    </>
  );
};
