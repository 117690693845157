import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const StoryManagement = loadable(
  () => import('@routes/ContentStoryManagement/pages/StoryData'),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);

const CreateStory = loadable(
  () => import('@routes/ContentStoryManagement/pages/CreateStory'),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);

export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/story-data"
        permission={['Admin']}
        component={StoryManagement}
      />
      <ContentRoute
        exact
        path="/story-data/create-story"
        permission={['Admin']}
        component={CreateStory}
      />
      <ContentRoute
        exact
        path="/story-data/create-story/:id"
        permission={['Admin']}
        component={CreateStory}
      />
    </>
  );
};
