import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const MembershipList = loadable(
  () => import('@routes/Membership/pages/MembershipList'),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
const MembershipDetail = loadable(
  () => import('@routes/Membership/pages/MembershipDetail'),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
const MembershipEdit = loadable(
  () => import('@routes/Membership/pages/MembershipEdit'),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);

export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/loop-membership"
        permission={['Admin']}
        component={MembershipList}
      />
      <ContentRoute
        exact
        path="/loop-membership/:name/:id"
        permission={['Admin']}
        component={MembershipDetail}
      />
      <ContentRoute
        exact
        path="/loop-membership/:name/edit/:id"
        permission={['Admin']}
        component={MembershipEdit}
      />
    </>
  );
};
