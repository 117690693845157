/* eslint-disable no-extend-native */
export const getOnlyDate = (date, separator = '/') => {
  if (date) {
    return new Date(date)
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, separator);
  }
  return new Date()
    .toJSON()
    .slice(0, 10)
    .replace(/-/g, separator);
};

export const getDateAndTime = date => {
  if (date) {
    return new Date(date)
      .toLocaleString()
      .split(',')
      .join(' ');
  }
  return '-';
};

export const getDateDDMMYYYY = date => {
  if (date) {
    let currentMonth = new Date(date).getMonth() + 1;
    let currentDate = new Date(date).getDate();
    if (new Date(date).getMonth() < 10) {
      currentMonth = `0${currentMonth}`;
    }
    if (new Date(date).getDate() < 10) {
      currentDate = `0${currentDate}`;
    }
    const currentYear = new Date(date).getFullYear();

    return `${currentDate}/${currentMonth}/${currentYear}`;
  }
  return '-';
};

export const getDateYYYYMMDD = date => {
  if (date) {
    let currentMonth = new Date(date).getMonth() + 1;
    let currentDate = new Date(date).getDate();
    if (new Date(date).getMonth() < 10) {
      currentMonth = `0${currentMonth}`;
    }
    if (new Date(date).getDate() < 10) {
      currentDate = `0${currentDate}`;
    }
    const currentYear = new Date(date).getFullYear();

    return `${currentYear}-${currentMonth}-${currentDate}`;
  }
  return '-';
};

export const getDateWithText = (date, withDay = false) => {
  if (date) {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'Mei',
      'Jun',
      'Jul',
      'Agus',
      'Sep',
      'Okt',
      'Nov',
      'Des'
    ];
    const dayName = new Date(date).toLocaleString('id', { weekday: 'long' });
    const currentDate = new Date(date).getDate();
    const currentMonth = monthNames[new Date(date).getMonth()];
    const currentYear = new Date(date).getFullYear();

    return `${
      withDay ? `${dayName},` : ''
    } ${currentDate} ${currentMonth} ${currentYear}`;
  }
  return '-';
};

export const getCurrentWeek = (firstDate, lastDate) => {
  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = startDate;
    const addDays = function(days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }
  const dates = getDates(new Date(firstDate), new Date(lastDate));
  return dates;
};

export const getFirstAndLastDayPerWeek = date => {
  let today = new Date();
  if (date) {
    today = new Date(date);
  }
  const firstDay = new Date(
    today.setDate(today.getDate() - today.getDay() + 1)
  );
  const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));

  return {
    firstDay,
    lastDay
  };
};

export const getNextWeek = date => {
  Date.prototype.getNextWeekDay = function(d) {
    if (d) {
      var next = this;
      next.setDate(this.getDate() - this.getDay() + 7 + d);
      return next;
    }
  };

  var now = new Date();
  if (date) {
    now = new Date(date);
  }
  var nextMonday = now.getNextWeekDay(1);
  return nextMonday;
};

export const getOnlyHoursAndMinutes = time => {
  if (time) {
    return time.replace(/:[^:]*$/, '');
  }
  return null;
};

export const getOnlyHoursAndMinutesFromDate = date => {
  if (date) {
    const now = new Date(date);
    return `${now.getHours() < 10 ? `0${now.getHours()}` : now.getHours()}:${
      now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes()
    }`;
  }
  return null;
};

export const getTimeFromDate = date => {
  if (date) {
    const now = new Date(date);
    return `${now.getHours() < 10 ? `0${now.getHours()}` : now.getHours()}:${
      now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes()
    }:${now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds()} ${
      now.getHours() < 12 ? 'AM' : 'PM'
    } `;
  }
  return null;
};

export const roundDecimal = (num = 0) => {
  return Math.round(num * 10) / 10;
};
