import { API } from '@/utility/API';
import { errMessage } from './Error';
import _ from 'lodash';

export const notificationList = obj => dispatch =>
  new Promise((resolve, reject) => {
    API()
      .notifications(obj)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        dispatch(errMessage(_.result(response, 'data.message')));
        reject(response);
      });
  });

export const updateNotification = obj => dispatch =>
  new Promise((resolve, reject) => {
    API()
      .updateNotificationToken(obj)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        dispatch(errMessage(_.result(response, 'data.message')));
        reject(response);
      });
  });

export const readNotification = obj => dispatch =>
  new Promise((resolve, reject) => {
    API()
      .readNotifications(obj)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        dispatch(errMessage(_.result(response, 'data.message')));
        reject(response);
      });
  });
