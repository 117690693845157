import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const MasterDataTagList = loadable(
  () =>
    import(
      /* webpackChunkName: "tag-management-list" */ '@routes/MasterDataTag/pages/TagList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
const MasterDataTagCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "tag-management-create" */ '@routes/MasterDataTag/pages/NewTag/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
const MasterDataTagUpdate = loadable(
  () =>
    import(
      /* webpackChunkName: "tag-management-update" */ '@routes/MasterDataTag/pages/TagDetail/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/tag-management"
        permission={['Admin']}
        component={MasterDataTagList}
      />
      <ContentRoute
        exact
        path="/tag-management/create"
        permission={['Admin']}
        component={MasterDataTagCreate}
      />
      <ContentRoute
        exact
        path="/tag-management/detail/:id"
        permission={['Admin']}
        component={MasterDataTagUpdate}
      />
    </>
  );
};
