/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export function BreadCrumbs({ items, newVersion }) {
  if (!items || !items.length) {
    return '';
  }

  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-boldest my-2 p-0">
      {items.map((item, index) => (
        <li
          className={`${newVersion ? 'new-' : ''}breadcrumb-item`}
          key={index}
        >
          <Link
            className={
              newVersion
                ? items.length - 1 === index
                  ? 'active'
                  : ''
                : 'text-success'
            }
            to={{ pathname: item.pathname }}
          >
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}

BreadCrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  newVersion: PropTypes.bool
};

BreadCrumbs.defaultProps = {
  items: [],
  newVersion: false
};
