import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const ContentAnalyticList = loadable(
  () =>
    import(
      /* webpackChunkName: "content-analytic-list" */ '@routes/ContentAnalytic/pages/ContentAnalyticList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/content-analytic"
        permission={['Admin']}
        component={ContentAnalyticList}
      />
    </>
  );
};
