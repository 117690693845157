import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(6),
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    textAlign: 'center'
  },
  primaryButton: {
    marginTop: theme.spacing(4)
  },
  icon: {
    display: 'inline-block',
    marginBottom: theme.spacing(2),
    fontSize: 50
  }
}));

const NoPermission = ({ goBackLink }) => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <div className={classes.root}>
      <span role="img" aria-label="No access" className={classes.icon}>
        🙅🏻‍♂️
      </span>
      <Typography variant="h2" gutterBottom>
        Sorry, you don&rsquo;t have any permission to access this page.
      </Typography>
      <Typography variant="p">
        You might want to refresh your browser to get the latest permission
        access.
      </Typography>
      <div>
        <Button
          variant="outlined"
          className={classes.primaryButton}
          onClick={() => {
            history.push(goBackLink);
            window.location.reload();
          }}
        >
          Reload and back to Dashboard
        </Button>
      </div>
    </div>
  );
};

NoPermission.defaultProps = {
  goBackLink: '/store-management'
};

NoPermission.propTypes = {
  goBackLink: PropTypes.string
};

export default NoPermission;
