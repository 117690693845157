import { API, APIGENERIC } from '@/utility/API';
import { showLoadingBar, hideLoadingBar } from './Loading';
import { errMessage } from './Error';
import { successMessage } from './Success';
import Cookies from 'js-cookie';
import _ from 'lodash';

export const login = obj => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(showLoadingBar());
    APIGENERIC()
      .login(obj)
      .then(({ data }) => {
        let now = new Date();
        now.setTime(now.getTime() + 480 * 60 * 1000);
        Cookies.set('isLogin', true, { expires: now });
        localStorage.setItem('profile', JSON.stringify(data?.data?.admin));
        localStorage.setItem('token', data?.data?.token);
        dispatch(hideLoadingBar());
        dispatch(successMessage('Berhasil Login'));
        resolve(data);
      })
      .catch(({ response }) => {
        dispatch(errMessage(_.result(response, 'data.message')));
        dispatch(hideLoadingBar());
        reject(response);
      });
  });

export const logout = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(showLoadingBar());
    API()
      .logout()
      .then(({ data }) => {
        Cookies.remove('isLogin');
        localStorage.clear();
        setTimeout(() => {
          window.location.href = '/';
        }, 200);
        dispatch(hideLoadingBar());
        dispatch(successMessage('Berhasil Logout'));
        resolve(data);
      })
      .catch(({ response }) => {
        Cookies.remove('isLogin');
        localStorage.clear();
        dispatch(errMessage(_.result(response, 'data.message')));
        dispatch(hideLoadingBar());
        reject(response);
      });
  });

export const requestForgotPassword = obj => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(showLoadingBar());
    APIGENERIC()
      .requestForgotPassword(obj)
      .then(({ data }) => {
        dispatch(hideLoadingBar());
        dispatch(successMessage(_.result(data, 'data.message')));
        resolve(data);
      })
      .catch(({ response }) => {
        Cookies.remove('isLogin');
        localStorage.clear();
        dispatch(errMessage(_.result(response, 'data.message')));
        dispatch(hideLoadingBar());
        reject(response);
      });
  });

export const verifyOtp = obj => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(showLoadingBar());
    APIGENERIC()
      .verifyOtp(obj)
      .then(({ data }) => {
        dispatch(hideLoadingBar());
        dispatch(successMessage(_.result(data, 'data.message')));
        resolve(data);
      })
      .catch(({ response }) => {
        Cookies.remove('isLogin');
        localStorage.clear();
        dispatch(errMessage(_.result(response, 'data.message')));
        dispatch(hideLoadingBar());
        reject(response);
      });
  });

export const resetPassword = obj => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(showLoadingBar());
    APIGENERIC()
      .resetPassword(obj)
      .then(({ data }) => {
        dispatch(hideLoadingBar());
        dispatch(successMessage(_.result(data, 'data.message')));
        resolve(data);
      })
      .catch(({ response }) => {
        Cookies.remove('isLogin');
        localStorage.clear();
        dispatch(errMessage(_.result(response, 'data.message')));
        dispatch(hideLoadingBar());
        reject(response);
      });
  });

export const deleteAccounts = token => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(showLoadingBar());
    API()
      .deleteAccounts(token)
      .then(({ data }) => {
        dispatch(hideLoadingBar());
        resolve(data);
      })
      .catch(({ response }) => {
        dispatch(errMessage(_.result(response, 'data.message')));
        dispatch(hideLoadingBar());
        reject(response);
      });
  });
