export const SHOW_LOADING_BAR = 'SHOW_LOADING_BAR';
export const HIDE_LOADING_BAR = 'HIDE_LOADING_BAR';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = false, action) => {
  switch (action.type) {
    case SHOW_LOADING_BAR:
      return true;
    case HIDE_LOADING_BAR:
      return false;
    default:
      return state;
  }
};

export function showLoadingBar() {
  return dispatch =>
    dispatch({
      type: SHOW_LOADING_BAR
    });
}

export function hideLoadingBar() {
  return dispatch =>
    dispatch({
      type: HIDE_LOADING_BAR
    });
}
