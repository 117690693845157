/* eslint-disable react/prop-types */
import React, { createContext, useState, useContext } from 'react';

export function getTitle(breadCrumbs, pathname) {
  if (!breadCrumbs || !pathname) {
    return '';
  }

  const length = breadCrumbs.length;
  if (!length) {
    return '';
  }

  return breadCrumbs[length - 1].title;
}

const SubheaderContext = createContext();

export function useSubheader() {
  return useContext(SubheaderContext);
}

export const SubheaderConsumer = SubheaderContext.Consumer;

export function MetronicSubheaderProvider({ children }) {
  const [title, setTitle] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [newVersion, setNewVersion] = useState(false);
  const value = {
    title,
    setTitle,
    breadcrumbs,
    setBreadcrumbs,
    newVersion,
    setNewVersion
  };
  return (
    <SubheaderContext.Provider value={value}>
      {children}
    </SubheaderContext.Provider>
  );
}
