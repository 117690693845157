import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const UserAnalyticList = loadable(
  () =>
    import(
      /* webpackChunkName: "user-analytic-list" */ '@routes/UserAnalytic/pages/UserAnalyticList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/user-analytic"
        permission={['Admin']}
        component={UserAnalyticList}
      />
    </>
  );
};
