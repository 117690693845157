import React from 'react';
import ReactDOM from 'react-dom/client';
// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from './redux/storeConfig/store';
import App from './App';
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from '@metronic/layout';
import 'socicon/css/socicon.css';
import 'react-datepicker/dist/react-datepicker.css';
import '@styles/index.scss';
import '@metronic/_assets/plugins/keenthemes-icons/font/ki.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const rootElement = ReactDOM.createRoot(document.getElementById('root'));

rootElement.render(
  <Provider store={store}>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <App />
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </Provider>
);
