export const SHOW_ERROR = 'SHOW_ERROR';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = [], action) => {
  switch (action.type) {
    case SHOW_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export function errMessage(data) {
  return dispatch =>
    dispatch({
      type: SHOW_ERROR,
      payload: data
    });
}
