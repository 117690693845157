/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { makeStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { toAbsoluteUrl } from '@metronic/_helpers';
import { ContentRoute } from '@metronic/layout';
import Login from './Login';
import ConfirmForgotPassword from './ConfirmForgotPassword';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import '@metronic/_assets/sass/pages/login/classic/login-1.scss';
import { errMessage } from '@redux/ducks/Error';
import { successMessage } from '@redux/ducks/Success';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: '-11rem'
  },
  backgroundImage: {
    backgroundImage: `url(${toAbsoluteUrl('/media/bg/empty-bg.svg')})`
  },
  backgroundImageDoctorSection: {
    width: '37rem',
    height: '37rem',
    borderRadius: '50%',
    backgroundColor: 'rgb(229,2442,242)',
    // eslint-disable-next-line no-dupe-keys
    backgroundColor: 'rgb(255,255,255, 0.5)'
  }
}));

export function AuthPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalState = useSelector(state => state);
  const [open] = React.useState(true);
  const logout = () => {
    Cookies.remove('isLogin');
    localStorage.clear();
    setTimeout(() => {
      window.location.href = '/';
    }, 200);
  };
  return (
    <>
      {globalState?.error?.error === 'Session expired' ||
      globalState?.error?.error === 'Token expired.' ? (
        logout()
      ) : globalState?.error?.error ? (
        <Snackbar
          open={open}
          onClose={() => dispatch(errMessage())}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => dispatch(errMessage())}
            severity="error"
            sx={{ width: '100%' }}
          >
            {globalState?.error?.error}
          </Alert>
        </Snackbar>
      ) : globalState?.success?.success ? (
        <Snackbar
          open={open}
          onClose={() => dispatch(successMessage())}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => dispatch(successMessage())}
            severity="success"
            sx={{ width: '100%' }}
          >
            {globalState?.success?.success}
          </Alert>
        </Snackbar>
      ) : null}
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className={`${classes.backgroundImage} login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-0`}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto text-center">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl('/media/bg/rectangle.svg')}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div
                className={`${classes.marginTop} flex-column-fluid d-flex flex-column align-items-center image-section`}
              >
                <img
                  className={`${classes.backgroundImageDoctorSection} doctor-image`}
                  alt="Logo"
                  src={toAbsoluteUrl('/media/bg/smiling-doctor.svg')}
                />
                <div className="mt-20">
                  <img
                    src={toAbsoluteUrl('/media/bg/loop-health.svg')}
                    alt=""
                  />
                </div>
                <div className="mt-5">
                  <img
                    src={toAbsoluteUrl('/media/bg/loop-health-text.svg')}
                    alt=""
                  />
                </div>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-boldest	text-white">
                  &copy; 2020 Metronic
                </div>
                <div className="d-flex">
                  <Link to="/terms" className="text-white">
                    Privacy
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Legal
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Contact
                  </Link>
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-lg-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" fullLayout component={Login} />
                <ContentRoute
                  path="/auth/verify-otp"
                  fullLayout
                  component={ConfirmForgotPassword}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  fullLayout
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/reset-password"
                  fullLayout
                  component={ResetPassword}
                />
                <Redirect from="/auth" exact to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
