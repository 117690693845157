// ** Redux Imports
import { combineReducers } from 'redux';
import loading from './ducks/Loading';
import error from './ducks/Error';
import success from './ducks/Success';

const rootReducer = combineReducers({
  loading,
  error,
  success
});

export default rootReducer;
