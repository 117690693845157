import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const DeleteAccountForm = loadable(() => import('./DeleteAccountForm'), {
  ssr: true,
  fallback: <LayoutSplashScreen />
});
const DeleteAccountDirect = loadable(() => import('./DeleteAccountDirect'), {
  ssr: true,
  fallback: <LayoutSplashScreen />
});

export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/auth/delete-account"
        fullLayout
        component={DeleteAccountForm}
      />
      <ContentRoute
        exact
        path="/auth/delete-account/direct"
        fullLayout
        component={DeleteAccountDirect}
      />
    </>
  );
};
