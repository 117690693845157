/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, '');
    localStorage.setItem(key + '_expiresIn', '');
  } catch (e) {
    console.log(
      'removeStorage: Error removing key [' +
        key +
        '] from localStorage: ' +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage() {
  try {
    const value = localStorage.getItem('profile');
    return JSON.parse(value);
  } catch (e) {
    console.log(
      'getStorage: Error reading key [' +
        key +
        '] from localStorage: ' +
        JSON.stringify(e)
    );
    return null;
  }
}

/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 480 * 60; // default: 8 hours
  }

  const now = Date.now();
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + '_expiresIn', schedule);
  } catch (e) {
    console.log(
      'setStorage: Error setting key [' +
        key +
        '] in localStorage: ' +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}
