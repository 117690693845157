import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const ArticleManagementList = loadable(
  () =>
    import(
      /* webpackChunkName: "article-management-list" */ '@routes/ArticleManagement/pages/ArticleList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
const ArticleManagementCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "article-management-create" */ '@routes/ArticleManagement/pages/NewArticle/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
const ArticleManagementDetail = loadable(
  () =>
    import(
      /* webpackChunkName: "article-management-update" */ '@routes/ArticleManagement/pages/ArticleDetail/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/article-management"
        permission={['Admin']}
        component={ArticleManagementList}
      />
      <ContentRoute
        exact
        path="/article-management/create"
        permission={['Admin']}
        component={ArticleManagementCreate}
      />
      <ContentRoute
        exact
        path="/article-management/detail/:id"
        permission={['Admin']}
        component={ArticleManagementDetail}
      />
    </>
  );
};
