import React, { useMemo } from 'react';
import objectPath from 'object-path';
import { node } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Cookies from 'js-cookie';
// LayoutContext
import { useHtmlClassService } from '../_core/MetronicLayout';
// Import Layout components
import { HeaderMobile } from './header-mobile/HeaderMobile';
import { Aside } from './aside/Aside';
import { LayoutInit } from './LayoutInit';
import { SubHeader } from './subheader/SubHeader';
import MuiAlert from '@mui/material/Alert';
import { errMessage } from '@redux/ducks/Error';
import { successMessage } from '@redux/ducks/Success';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function Layout({ children }) {
  const uiService = useHtmlClassService();
  const open = true;
  const dispatch = useDispatch();
  const globalState = useSelector(state => state);
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      selfLayout: objectPath.get(uiService.config, 'self.layout'),
      subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended')
    };
  }, [uiService]);

  const logout = () => {
    Cookies.remove('isLogin');
    localStorage.clear();
    setTimeout(() => {
      window.location.href = '/';
    }, 200);
  };

  return layoutProps.selfLayout !== 'blank' ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />

      {globalState?.error?.error === 'Session expired' ||
      globalState?.error?.error === 'Token expired.' ? (
        logout()
      ) : globalState?.error?.error ? (
        <Snackbar
          open={open}
          onClose={() => dispatch(errMessage())}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => dispatch(errMessage())}
            severity="error"
            sx={{ width: '100%' }}
          >
            {globalState?.error?.error}
          </Alert>
        </Snackbar>
      ) : globalState?.success?.success ? (
        <Snackbar
          open={open}
          onClose={() => dispatch(successMessage())}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => dispatch(successMessage())}
            severity="success"
            sx={{ width: '100%' }}
          >
            {globalState?.success?.success}
          </Alert>
        </Snackbar>
      ) : null}

      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          <Aside />

          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid bg-white`}
            >
              {layoutProps.subheaderDisplay && <SubHeader />}

              {/*begin::Entry*/}
              {layoutProps.contentExtended && <>{children}</>}

              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid bg-white">
                  {/*begin::Container*/}
                  <div className={layoutProps.contentContainerClasses}>
                    {children}
                  </div>
                  {/*end::Container*/}
                </div>
              )}

              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}

Layout.propTypes = {
  children: node.isRequired
};
