import React, { Suspense } from 'react';
import { Redirect, Switch, Route, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Layout, LayoutSplashScreen, ContentRoute } from '@metronic/layout';
import { Logout, AuthPage } from '@metronic/modules/Auth';
import ErrorsPage from '@metronic/modules/ErrorsExamples/ErrorsPage';
import AdminManagement from './AdminManagement';
import PartnerManagement from './PartnerManagement';
import PatientManagement from './PatientManagement';
import ProgramManagement from './ProgramManagement';
import ArticleManagement from './ArticleManagement';
import RecipeManagement from './RecipeManagement';
import FoodManagement from './FoodManagement';
import DeliveryManagement from './DeliveryManagement';
import MasterDataTag from './MasterDataTag';
import MasterDataActivity from './MasterDataActivity';
import MasterDataCategory from './MasterDataCategory';
import MasterDataConfiguration from './MasterDataConfiguration';
import BusinessAnalytic from './BusinessAnalytic';
import UserAnalytic from './UserAnalytic';
import ContentAnalytic from './ContentAnalytic';
import Coaching from './Coaching';
import CoachingSession from './CoachingSession';
import _ from 'lodash';
import DeleteAccounts from '@metronic/modules/Auth/pages/DeleteAccounts';
import Membership from './Membership';
import ContentStoryManagement from './ContentStoryManagement';

const BasePage = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute
          path="/admin-management"
          component={AdminManagement}
          permission={['Admin']}
        />
        <ContentRoute
          path="/partner-management"
          component={PartnerManagement}
          permission={['Admin']}
        />
        <ContentRoute
          path="/coaching"
          component={Coaching}
          permission={['Admin', 'Coach']}
        />
        <ContentRoute
          path="/coaching-session"
          component={CoachingSession}
          permission={['Admin', 'Coach']}
        />
        <ContentRoute
          path="/patient-management"
          component={PatientManagement}
          permission={['Admin', 'Coach']}
        />
        <ContentRoute
          path="/program-management"
          component={ProgramManagement}
          permission={['Admin']}
        />
        <ContentRoute
          path="/article-management"
          component={ArticleManagement}
          permission={['Admin']}
        />
        <ContentRoute
          path="/delivery-management"
          component={DeliveryManagement}
          permission={['Admin']}
        />
        <ContentRoute
          path="/tag-management"
          component={MasterDataTag}
          permission={['Admin']}
        />
        <ContentRoute
          path="/activity-management"
          component={MasterDataActivity}
          permission={['Admin']}
        />
        <ContentRoute
          path="/category-management"
          component={MasterDataCategory}
          permission={['Admin']}
        />
        <ContentRoute
          path="/configuration-management"
          component={MasterDataConfiguration}
          permission={['Admin']}
        />
        <ContentRoute
          path="/business-analytic"
          component={BusinessAnalytic}
          permission={['Admin']}
        />
        <ContentRoute
          path="/user-analytic"
          component={UserAnalytic}
          permission={['Admin']}
        />
        <ContentRoute
          path="/content-analytic"
          component={ContentAnalytic}
          permission={['Admin']}
        />
        <ContentRoute
          path="/recipe-management"
          component={RecipeManagement}
          permission={['Admin']}
        />
        <ContentRoute
          path="/food-management"
          component={FoodManagement}
          permission={['Admin', 'Coach']}
        />
        <ContentRoute
          path="/loop-membership"
          component={Membership}
          permission={['Admin']}
        />
        <ContentRoute
          path="/story-data"
          component={ContentStoryManagement}
          permission={['Admin']}
        />
      </Switch>
    </Suspense>
  );
};

export default function Routes() {
  const isAuthorized = Cookies.get('isLogin');
  const location = useLocation();
  const isDeleteAccount = _.toLower(location.pathname).includes(
    'delete-account'
  );

  return (
    <Switch>
      {!isAuthorized ? (
        isDeleteAccount ? (
          <DeleteAccounts />
        ) : (
          <Route>
            <AuthPage />
          </Route>
        )
      ) : (
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
