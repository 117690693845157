/* eslint-disable react/prop-types */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import includes from 'lodash/includes';
import uniq from 'lodash/uniq';
import userSVG from '@assets/icons/manajemen-pengguna-icon.svg';
import userSVGActive from '@assets/icons/manajemen-pengguna-aktif-icon.svg';
import dashboardSVG from '@assets/icons/dashboard-menu-icon.svg';
import dashboardSVGActive from '@assets/icons/dashboard-icon-aktif.svg';
import contentSVG from '@assets/icons/manajemen-konten-icon.svg';
import contentSVGActive from '@assets/icons/manajemen-konten-aktif.svg';
import masterDataSVG from '@assets/icons/master-data-icon.svg';
import configSVG from '@assets/icons/config-icon.svg';
import foodSVG from '@assets/icons/penilaian-makanan-icon.svg';
import deliverySVG from '@assets/icons/delivery-icon.svg';
import membershipSVG from '@assets/icons/membership.svg';
import membershipActiveSVG from '@assets/icons/membership-active.svg';
import storyIconSVG from '@assets/icons/story-icon.svg';
import deliverySVGActive from '@assets/icons/delivery-icon-active.svg';
import masterDataSVGActive from '@assets/icons/master-data-icon-active.svg';
import { getStorage, checkIsActive } from '@metronic/_helpers/';

const useStyles = makeStyles(() => ({
  borderRadius: {
    borderRadius: '12px'
  },
  childMenuBackground: {
    marginTop: '-8px',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    backgroundColor: '#F2F9FF'
  }
}));

const menu = [
  {
    id: 1,
    name: 'Dashboard',
    url: '/',
    role: ['Admin'],
    icon: dashboardSVG,
    iconActive: dashboardSVGActive,
    child: [
      {
        id: 2,
        name: 'Analisa Bisnis',
        url: '/business-analytic',
        icon: dashboardSVG,
        role: ['Admin']
      },
      {
        id: 3,
        name: 'Analisa Tren Pengguna',
        url: '/user-analytic',
        icon: dashboardSVG,
        role: ['Admin']
      },
      {
        id: 4,
        name: 'Analisa Konten',
        url: '/content-analytic',
        icon: dashboardSVG,
        role: ['Admin']
      }
    ]
  },
  {
    id: 5,
    name: 'Manajemen Konten',
    url: '/',
    role: ['Admin'],
    icon: contentSVG,
    iconActive: contentSVGActive,
    child: [
      {
        id: 18,
        name: 'Program',
        url: '/program-management',
        icon: contentSVG,
        role: ['Admin']
      },
      {
        id: 6,
        name: 'Artikel',
        url: '/article-management',
        icon: contentSVG,
        role: ['Admin']
      },
      {
        id: 16,
        name: 'Resep',
        url: '/recipe-management',
        icon: contentSVG,
        role: ['Admin']
      },
      {
        id: 21,
        name: 'Story Aplikasi',
        url: '/story-data',
        icon: storyIconSVG,
        role: ['Admin']
      }
    ]
  },
  {
    id: 22,
    name: 'Coaching',
    url: '/coaching-session',
    role: ['Admin', 'Coach'],
    icon: contentSVG,
    iconActive: contentSVGActive,
    child: null
  },
  {
    id: 7,
    name: 'Manajemen Pengguna',
    url: '/',
    role: ['Admin', 'Coach'],
    icon: userSVG,
    iconActive: userSVGActive,
    child: [
      {
        id: 8,
        name: 'Manajemen Pasien',
        url: '/patient-management',
        icon: userSVG,
        role: ['Admin', 'Coach']
      },
      {
        id: 17,
        name: 'Penilaian Makanan',
        url: '/food-management',
        icon: foodSVG,
        role: ['Admin', 'Coach']
      },
      {
        id: 9,
        name: 'Manajemen Partner',
        url: '/partner-management',
        icon: userSVG,
        role: ['Admin']
      },
      {
        id: 10,
        name: 'Manajemen Admin',
        url: '/admin-management',
        icon: userSVG,
        role: ['Admin']
      }
    ]
  },
  {
    id: 20,
    name: 'Loop Membership',
    url: '/loop-membership',
    role: ['Admin'],
    icon: membershipSVG,
    iconActive: membershipActiveSVG,
    child: null
  },
  {
    id: 19,
    name: 'Pengiriman',
    url: '/delivery-management',
    role: ['Admin'],
    icon: deliverySVG,
    iconActive: deliverySVGActive,
    child: null
  },
  {
    id: 11,
    name: 'Master Data',
    url: '/',
    role: ['Admin'],
    icon: masterDataSVG,
    iconActive: masterDataSVGActive,
    child: [
      {
        id: 12,
        name: 'Tag',
        url: '/tag-management',
        icon: masterDataSVG,
        role: ['Admin']
      },
      {
        id: 13,
        name: 'Aktifitas',
        url: '/activity-management',
        icon: masterDataSVG,
        role: ['Admin']
      },
      {
        id: 14,
        name: 'Kategori',
        url: '/category-management',
        icon: masterDataSVG,
        role: ['Admin']
      },
      {
        id: 15,
        name: 'Konfigurasi',
        url: '/configuration-management',
        icon: configSVG,
        role: ['Admin']
      }
    ]
  }
];

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const classes = useStyles();
  const checkRole = getStorage()?.role;
  const [menuId, setMenuId] = useState([]);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : '';
  };

  const expandCollapseMenu = (id, color) => {
    return !includes(menuId, id) ? (
      <ExpandMoreIcon className={color || ''} />
    ) : (
      <KeyboardArrowUpIcon className={color || ''} />
    );
  };

  return (
    <ul className={`menu-nav pt-0 ${layoutProps.ulClasses}`}>
      {menu.map(val =>
        val?.child
          ? val.role.includes(checkRole) && (
              <Fragment key={val.id}>
                <li
                  className={`menu-item mx-2 ${
                    classes.borderRadius
                  } ${checkIsActive(
                    location,
                    val?.child.map(value => value?.url)
                  ) && 'bg-primary'}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  onClick={() => {
                    const includeExpandId = includes(uniq(menuId), val?.id);
                    let newIncludeExpandId = val?.id;
                    if (includeExpandId) {
                      newIncludeExpandId = menuId.filter(
                        detail => detail !== val?.id
                      );
                      setMenuId(newIncludeExpandId);
                      return;
                    }
                    setMenuId(prevState => [...prevState, newIncludeExpandId]);
                  }}
                >
                  <NavLink className="menu-link menu-toggle" to="#">
                    <span className="svg-icon menu-icon">
                      <img
                        src={
                          checkIsActive(
                            location,
                            val?.child.map(value => value?.url)
                          )
                            ? val?.iconActive
                            : val?.icon
                        }
                        alt="user"
                      />
                    </span>
                    <span
                      className={`menu-text ${checkIsActive(
                        location,
                        val?.child.map(value => value?.url)
                      ) && 'text-white font-weight-boldest'}`}
                    >
                      {val?.name}
                    </span>
                    <span className="menu-text justify-content-end">
                      {expandCollapseMenu(
                        val?.id,
                        checkIsActive(
                          location,
                          val?.child.map(value => value?.url)
                        ) && 'text-white'
                      )}
                    </span>
                  </NavLink>
                </li>
                <ul
                  className={`menu-nav ${layoutProps.ulClasses} ${checkIsActive(
                    location,
                    val?.child.map(value => value?.url)
                  ) && classes.childMenuBackground} ${
                    includes(menuId, val?.id) ? 'd-block' : 'd-none'
                  }`}
                >
                  {val.child.map(
                    child =>
                      child.role.includes(checkRole) && (
                        <li
                          key={child.id}
                          className={`menu-item menu-item-submenu ml-6 ${getMenuItemActive(
                            [child?.url],
                            false
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to={child?.url}
                          >
                            <span className="svg-icon menu-icon">
                              <img src={child?.icon} alt="user" />
                            </span>
                            <span
                              className={`menu-text ${checkIsActive(location, [
                                child?.url
                              ]) && 'font-weight-boldest'}`}
                            >
                              {child?.name}
                            </span>
                          </NavLink>
                        </li>
                      )
                  )}
                </ul>
              </Fragment>
            )
          : val.role.includes(checkRole) && (
              <li
                key={val.id}
                className={`menu-item menu-item-submenu m-2 ${
                  classes.borderRadius
                } ${checkIsActive(location, val?.url) &&
                  'font-weight-boldest bg-primary'}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to={val?.url}>
                  <span className="svg-icon menu-icon">
                    <img
                      src={
                        checkIsActive(location, val?.url)
                          ? val?.iconActive
                          : val?.icon
                      }
                      alt="user"
                    />
                  </span>
                  <span
                    className={`menu-text ${checkIsActive(location, val?.url) &&
                      'font-weight-boldest text-white'}`}
                  >
                    {val?.name}
                  </span>
                </NavLink>
              </li>
            )
      )}
    </ul>
  );
}
