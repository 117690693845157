import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const ProgramManagementList = loadable(
  () =>
    import(
      /* webpackChunkName: "program-management-list" */ '@routes/ProgramManagement/pages/ProgramList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
const ProgramManagementCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "program-management-create" */ '@routes/ProgramManagement/pages/NewProgram/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
const ProgramManagementDetail = loadable(
  () =>
    import(
      /* webpackChunkName: "program-management-detail" */ '@routes/ProgramManagement/pages/ProgramDetail/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
const ProgramManagementEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "program-management-update" */ '@routes/ProgramManagement/pages/EditProgram/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/program-management"
        permission={['Admin']}
        component={ProgramManagementList}
      />
      <ContentRoute
        exact
        path="/program-management/create/:id/:title"
        permission={['Admin']}
        component={ProgramManagementCreate}
      />
      <ContentRoute
        exact
        path="/program-management/detail/:id/:title"
        permission={['Admin']}
        component={ProgramManagementDetail}
      />
      <ContentRoute
        exact
        path="/program-management/edit/:id/:title"
        permission={['Admin']}
        component={ProgramManagementEdit}
      />
    </>
  );
};
